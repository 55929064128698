*,
*::after,
*::before {
    margin: 0;
    box-sizing: border-box;
  
}

@import 'https://fonts.googleapis.com/css?family=Baloo+Paaji';

header{
  width: 100%;
  background-color: #000000;
  letter-spacing: 3px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Poppins', sans-serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  
}


.logo:hover {
  opacity: 1;
}



a:hover {
  opacity: 0.7;
}

nav a::after {
  content: " ";
  width: 0%;
  height: 4px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: -8px;
  left: 0;
  transition: 0.5s ease-in-out;
}

nav a:hover::after {
  width: 100%;
}

header .container {
  display: flex; 
  align-items: center;
  justify-content: space-around;
  height: 13.7vh;
  margin: auto;
}

header nav {
  display: flex;
}

header nav ul {
  display: flex;
}

header nav ul li {
  list-style: none;
}

header nav ul li a {
  text-decoration: none;
  color: #ffffff;
  margin-left: 32px;
  transition: all 250ms linear 0s;
}

header nav ul li a{
  position: relative;
  animation-name: menu;
  animation-duration: 1s;
  transition: 100ms;
}

header nav ul li a .FiLogOut{
  position: relative;
  top: 2px;
  left: 2px;
}

@keyframes menu {
  0%   { left:0px; top: -100px;}
  100% { left: 0px; top:0px;}
  
}

@media (max-width: 630px){
  html{
    font-size: 70%;
  }
 .hero .container{
   flex-direction: column;
   text-align: center;
 }

 .form-group{
   display: block;
 }
}

@media (max-width: 780px){
 
   .menu-section nav{
    display: none;
  }

  .one,
  .two,
  .three{
    background-color:#ffffff;
    height: 5px;
    width: 100%;
    margin: 6px auto;
    transition-duration: 0.5s;
  }
  .menu-toggle{
    width: 40px;
    height: 30px;
    margin-right: 20px;
  }

  /*Fullscreen*/
  .menu-section.on {
    position: absolute;
    top:0;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    z-index: 10; /*camada acima, evitar o scrool dos itens a baixo*/
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-section.on nav{
    display: block;
    margin-right: 75px;
  }

  
  .menu-section.on .menu-toggle{
    position: absolute;
    right: 25px;
    top: 25px;
  }

  
  .menu-section.on .menu-toggle .one{
    transform: rotate(45deg) translate(7px, 7px);
    background-color: #000000;
  }

  .menu-section.on .menu-toggle .two{
   opacity: 0;

  }

  .menu-section.on .menu-toggle .three{
    transform: rotate(-45deg) translate(8px, -9px);
    background-color: #000000;
  }

  .menu-section.on nav ul{
    text-align: center;
    display: block;
  }

  .menu-section.on nav ul a{ 
   transition-duration:  0.5s;
   font-size: 2.0rem;
    display: block;
    color: #000000;
    padding: 1.8rem;
  }
  
header nav ul li a .FiLogOut{
width: 40px;
}
  .menu-section.on nav ul .hide{ 
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0rem;
     font-size: 1.6rem;
   }

}

@media (max-width: 320px){
  #form h3{
    font-size: 2rem;
  }

}
