.banner {
    /* background: #000046;
  
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046);

    background: linear-gradient(to right, #1CB5E0, #000046); */
    background: #0575E6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #021B79, #0575E6);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #021B79, #0575E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    

    width: 100%;
    min-height: 578px;
    background-size: contain;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b-container {
    display: flex;
    justify-content: center;
    color: #001a41;
    position: relative;
  }

.info-banner {
    gap: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info-banner span {
    font-weight: bold;
    margin-left: 7px;
}

.break {
    color: #ffffff;
}

.button {
    width: 41%;
    font-size: 14px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 2em;
    padding: 14px 20px;
    overflow: hidden;
    background-color: #000000;
}

.button:hover {
    border: 1px solid black;
    transition-delay: 0.2s;
    transform: scale(1.1);
    transition: all 0.5s;
    color: white;
    font-weight: 400;
}
.phone {
    margin-right: 18px;
    margin-left: 30px;
    margin-top: 2.5%;
    width: 483px;
    transform: scaleX(-1);
  }
  
  .placa {
    border: 3px solid black;
    border-radius: 60%;
    position: absolute;
    bottom: -4%;
    right: -4%;
    width: 100px; /* Ajuste o tamanho conforme necessário */
  }
  

/* h1 {
    animation-duration: 3s;
    animation-name: slidein;
  }
  
  @keyframes slidein {
    from {
      margin-left: 50%;
      width: 300%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  } */

.banner h1 {
    font-size: 48px;
    line-height: 62px;
    color: white;
}

.bio {
    font-weight: bold;
    color: #ffffff;
}

.bio2 {
    color: #ffffff;
}


@media (max-width: 630px) {
    .banner {
        min-height: auto;
        height: 800px;
    }

    .b-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .phone {
        margin: 0;
        width: 100%;
        max-width: 350px;
        transform: none;
    }

    .info-banner {
        margin-top: 20px;
        margin-bottom: 40px;
        text-align: center;
    }

    .button {
        width: 70%;
        padding: 12px 18px;
        margin-left: 55px;
    }

    .banner h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .bio {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .bio2 {
        font-size: 14px;
    }
}