.sobre-container {
    display: flex;
    justify-content: center;
    height: 1470px;
    /* background: #fcfbfb; */
    background: #0575E6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #021B79, #0575E6);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #021B79, #0575E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
}

.title-sobrenos span {
    opacity: 0.1;
    font-weight: 600;
    font-size: 100px;
    color: #ffffff
}

.title-sobrenos h2 {
    margin-top: 80px;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    line-height: 3px;
    color: #ffffff;
}

.title-sobrenos p {
    margin-top: 55px;
    text-align: center;
}

@media screen and (max-width: 630px) {

    .sobre-container {
        height: 2835px;
    }

    .title-sobrenos {
      padding: 0 15px;
      margin-top: 25px;
    }
  
    .title-sobrenos span {
      font-size: 60px;
    }
  
    .title-sobrenos h2 {
      margin-top: 40px;
      font-size: 26px;
    }
  
    .title-sobrenos p {
      margin-top: 35px;
      font-size: 15px;
    }
  }
  
  @media screen and (max-width: 400px) {
    .sobre-container {
      height: 2935px;
  }
}

@media screen and (max-width: 300px) {
  .sobre-container {
    height: 3835px;
}
}