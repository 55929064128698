
.s-container {
    height: 900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -130px;
    margin-bottom: -150px;
}


.text-servicos h2 {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    line-height: 3px;
    color: #252A37;
}

.text-servicos span {
    opacity: 0.1;
    font-weight: 600;
    font-size: 100px;
    color: #022f74
}

.title-servicos span {
    text-transform: uppercase;
    color: #0095d6;
    font-weight: 500;
    cursor: pointer;
}

.text-servicos p {
    text-align: center;
    margin-top: 56px;
    color: #333333;
    font-size: 18px;
    font-weight: 400;
}

.servicos-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 60px;
}

.info-servicos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.circulo {
    width: 140px;
    height: 140px;
    background-color: #D3DDE7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circulo svg {
    width: 80px;
    height: 80px;
    color: white;
}

.circulo img {
    width: 80px;
}

.circulo:hover {
    transition: 0.5s;
    background-color: #001A41;
}

.title-servicos {
    text-align: center;
    margin-top: 16px;
}

.title-servicos h3 {
    margin-top: 13px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 13px;
}

@media screen and (max-width: 630px) {
    .s-container {
        height: auto;
        margin-top: 30px;
        margin-bottom: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .text-servicos h2 {
        margin-bottom: 50px;
        font-size: 24px;
        line-height: 1px;
    }

    .text-servicos span {
        font-size: 60px;
    }

    .text-servicos p {
        
        font-size: 16px;
        margin-top: 24px;
    }

    .servicos-wrapper {
        flex-wrap: wrap;
        gap: 30px;
    }

    .info-servicos {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;
    }

    .title-servicos {
        margin-top: 10px;
    }
}