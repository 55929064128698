* {
    font-family: 'Poppins', sans-serif;
}

main .cards {
    background-color: white;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.cards h3 {
    font-size: 22px;
}

main.cards section.card:first-child h3 {
    margin-top: 15px;
    margin-bottom: 15px;
}

main.cards section.card:nth-child(2) h3 {
    margin-top: 15px;
    margin-bottom: 15px;
}

main.cards section.card:nth-child(2) svg {
    color: #F23545;
}

main.cards section.card:nth-child(3) h3 {
    margin-top: 15px;
    margin-bottom: 15px;
}

main {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.cards {
    display: flex;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 1.5rem;
    height: 300px;

}

.card {
    width: 34%;
}

main.cards section.card span {
    max-width: 240px;
}

main.cards section.card:first-child {
    margin-left: 0;
    background-color: black;
    background-color: #0756F2;

}

main.cards section.card:nth-child(2) {
    color: white;
    background: black;
    /* fallback for old browsers */
}

main.cards section.card:nth-child(3) {
    color: white;
    background-color: #0756F2;
}

.c1 {
    background-color: rgb(0, 0, 0);
    color: white;
}

.c3 {
    background-color: rgb(0, 0, 0);
    color: white;
}

main.cards section.card svg {
    width: 60px;
    height: 60px;
}

@media screen and (max-width: 630px) {
    .cards {
        flex-direction: column;
        height: auto;
    }

    .card {
        font-size: 15px;
        width: 100%;
    }

    .card:last-child {
        margin-bottom: 0;
    }

    .card h3 {
        font-size: 18px;
    }

    .card span {
        max-width: 100%;
        margin-top: 3px;
    }

    .card svg {
        margin-top: 30px;
        width: 40px;
        height: 40px;
    }
}